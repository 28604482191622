import request from '@/utils/request'
const baseURL = '/baseapi'
const communityApi = {
  CommunityList: '/admin/community/list',
  CommunityInfo: '/admin/community/communityInfo',
  SaveCommunity: '/admin/community/saveCommunity',
  CheckCommunity: '/admin/community/checkCommunity',
  RegionCommunity: '/admin/community/regionCommunity',
  AllCommunityList: '/admin/community/allCommunityList',
  GetCodeUrl: '/admin/community/getCodeUrl',
  GetWxcode: '/admin/community/getWxcode'
}

/**
 * login func
 * parameter: {
 *     username: '',
 *     password: '',
 *     remember_me: true,
 *     captcha: '12345'
 * }
 * @param parameter
 * @returns {*}
 */

export function communityList (parameter) {
  return request({
    baseURL: baseURL,
    url: communityApi.CommunityList,
    method: 'post',
    data: parameter
  })
}

export function communityInfo (parameter) {
  return request({
    baseURL: baseURL,
    url: communityApi.CommunityInfo,
    method: 'post',
    data: parameter
  })
}

export function communityCodeUrl (parameter) {
  return request({
    baseURL: baseURL,
    url: communityApi.GetCodeUrl,
    method: 'post',
    data: parameter
  })
}

export function communityWxCodeUrl (parameter) {
  return request({
    baseURL: baseURL,
    url: communityApi.GetWxcode,
    method: 'post',
    data: parameter
  })
}

export function saveCommunity (parameter) {
  return request({
    baseURL: baseURL,
    url: communityApi.SaveCommunity,
    method: 'post',
    data: parameter
  })
}

export function checkCommunity (parameter) {
  return request({
    baseURL: baseURL,
    url: communityApi.CheckCommunity,
    method: 'post',
    data: parameter
  })
}

export function regionCommunity (parameter) {
  return request({
    baseURL: baseURL,
    url: communityApi.RegionCommunity,
    method: 'post',
    data: parameter
  })
}

export function allCommunityList () {
  return request({
    baseURL: baseURL,
    url: communityApi.AllCommunityList,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
