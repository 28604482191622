import request from '@/utils/request'
const baseURL = '/baseapi'
const workApi = {
  WorkList: '/admin/work/list',
  WorkInfo: '/admin/work/workInfo',
  WorkById: '/admin/work/workById',
  SaveWork: '/admin/work/saveWork',
  DelWork: '/admin/work/delWork',
  VerifyWork: '/admin/work/verifyWork',
  Condition: '/admin/work/Condition',
  SetMakeOrder: '/admin/work/setMakeOrder',
  WorkRecordList: '/admin/work/recordList',
  WorkRecordInfo: '/admin/work/workRecordInfo',
  DelWorkRecord: '/admin/work/delWorkRecord',
  VerifyWorkRecord: '/admin/work/verifyWorkRecord'
}

/**
 * login func
 * parameter: {
 *     username: '',
 *     password: '',
 *     remember_me: true,
 *     captcha: '12345'
 * }
 * @param parameter
 * @returns {*}
 */

export function workList (parameter) {
  return request({
    baseURL: baseURL,
    url: workApi.WorkList,
    method: 'post',
    data: parameter
  })
}

export function workInfo (parameter) {
  return request({
    baseURL: baseURL,
    url: workApi.WorkInfo,
    method: 'post',
    data: parameter
  })
}

export function workById (parameter) {
  return request({
    baseURL: baseURL,
    url: workApi.WorkById,
    method: 'post',
    data: parameter
  })
}

export function saveWork (parameter) {
  return request({
    baseURL: baseURL,
    url: workApi.SaveWork,
    method: 'post',
    data: parameter
  })
}

export function delWork (parameter) {
  return request({
    baseURL: baseURL,
    url: workApi.DelWork,
    method: 'post',
    data: parameter
  })
}

export function verifyWork (parameter) {
  return request({
    baseURL: baseURL,
    url: workApi.VerifyWork,
    method: 'post',
    data: parameter
  })
}

export function setMakeOrder (parameter) {
  return request({
    baseURL: baseURL,
    url: workApi.SetMakeOrder,
    method: 'post',
    data: parameter
  })
}

export function condition () {
  return request({
    baseURL: baseURL,
    url: workApi.Condition,
    method: 'get'
  })
}

export function workRecordList (parameter) {
  return request({
    baseURL: baseURL,
    url: workApi.WorkRecordList,
    method: 'post',
    data: parameter
  })
}

export function workRecordInfo (parameter) {
  return request({
    baseURL: baseURL,
    url: workApi.WorkRecordInfo,
    method: 'post',
    data: parameter
  })
}

export function verifyWorkRecord (parameter) {
  return request({
    baseURL: baseURL,
    url: workApi.VerifyWorkRecord,
    method: 'post',
    data: parameter
  })
}

export function delWorkRecord (parameter) {
  return request({
    baseURL: baseURL,
    url: workApi.DelWorkRecord,
    method: 'post',
    data: parameter
  })
}
