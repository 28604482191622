<template>
  <a-drawer
    :width="800"
    placement="right"
    @close="closeSeeInfo"
    :closable="true"
    :destroyOnClose="true"
    :visible="seeInfoVisible"
    :drawer-style="{ position: 'absolute' }"
  >
    <a-card :bordered="false">
      <div class="header-info" style="width: 700px;">
        <a-spin :spinning="loading">
          <h2 style="text-align: center;">{{ dataInfo.title }}</h2>
          <p style='text-align: right'>创建时间：{{ dataInfo.createTime }}</p>
          <p style='text-align: left'>区域：{{ dataInfo.areaCodeName }} {{ dataInfo.communityName }}</p>
          <p style='text-align: left'>时间段：{{ dataInfo.timeSlotStr }}</p>
          <p style='text-align: left'>
            <span>预约：</span>
            <a-tag color="#87d068" v-if="dataInfo.isMakeOrder > 0">已开启</a-tag>
            <a-tag color="#595959" v-else>已关闭</a-tag>
          </p>
          <div v-html="dataInfo.content"></div>
          <a-divider></a-divider>
        </a-spin>
      </div>
      <div style="text-align: center">
        <a-button
          @click="closeSeeInfo"
          style="margin-right: 10px;"
        >
          关闭
        </a-button>
      </div>
    </a-card>
  </a-drawer>
</template>

<script>
import { workInfo } from '@/api/work'
export default {
  name: 'WorkInfo',
  props: {
  },
  data () {
    return {
      seeInfoVisible: false,
      dealCheckStatus: false,
      labelCol: {
        lg: { span: 4 },
        sm: { span: 8 }
      },
      wrapperCol: {
        lg: { span: 20 },
        sm: { span: 17 }
      },
      gotoType: 1,
      loading: false,
      dataInfo: {
        keyId: 0,
        title: '',
        coverImage: '',
        content: '',
        createTime: ''
      }
    }
  },
  created () {
  },
  methods: {
    closeSeeInfo () {
      this.seeInfoVisible = false
    },
    workInfoApi (keyId) {
      const _this = this
      _this.seeInfoVisible = true
      _this.loading = true
      workInfo({ keyId: keyId }).then((res) => {
        if (res.errorCode === 0) {
          console.log(res)
          _this.dataInfo = res.result
          _this.loading = false
        }
      }).catch((err) => {
        _this.$message.error('系统错误')
        console.log(err)
        _this.loading = false
      })
    }
  }
}
</script>

<style lang="less" scoped>
</style>
